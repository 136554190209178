"use client";

import useIsMobile from "@/utils/useIsMobile";
import { type Payload } from "@local/payload-client/src/types";
import { classNames } from "@local/utils";
import { Suspense, useEffect, useMemo, useRef } from "react";
import { ContentBlockServer } from "../ContentBlock";
import { useI10n } from "@local/i10n";
import HydrationContainer from "../HydrationContainer";
const buid = process.env.NEXT_PUBLIC_TRUSTPILOT_BUID;
export const TrustpilotProductMiniMultiSource = ({
  skuArray,
  price
}: {
  skuArray?: string[];
  price: number;
}) => {
  const ref = useRef(null);
  const skus = skuArray?.join(",");
  const {
    currencyCode
  } = useI10n();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, 1000);
    return () => {
      timeout;
    };
  }, []);
  return buid && skus &&
  // Requires the Product Reviews TrustBox to be on the same page
  <HydrationContainer>
        <div ref={ref} className="pointer-events-none trustpilot-widget" data-locale="en-US" data-template-id="577258fb31f02306e4e3aaf9" data-businessunit-id={buid} data-sku={skus} data-style-height="24px" data-style-width="100%" data-theme="light" data-no-reviews="hide" data-scroll-to-list="true" data-style-alignment="right" onClick={e => e.preventDefault()} data-price={price} data-price-currency={currencyCode} data-availability={true}>
          {/* <a
           href={
            IS_HAM
              ? "https://www.trustpilot.com/review/hyperarchmotion.com"
              : "https://www.trustpilot.com/review/baerskintactical.com"
           }
           target="_blank"
           rel="noreferrer"
           className="text-indigo-600 underline"
           >
           Click here to see reviews at Trustpilot &rarr;
           </a> */}
        </div>
      </HydrationContainer>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const TrustpilotProductQandA = () => {
//TODO: Ok, so this guy is a synchronous script that loads externally, and if you use a ref to verify that Truspilot main script is loaded and then load the thing, it will haywire react, wipe out everything on the DOM and place the Q&A on an empty page.
//
//TODO: As you can see below, I've tried up to the point of loading the thing inside a Portal inside an iFrame, but no luck, and the network tab seems fine even.
//
// TODO: I've asked for help on trustpilot already.

// const [contentRef, setContentRef] = useState(null)
// const mountNode = (contentRef as any)?.contentWindow?.document?.body
// useEffect(() => {
//   if (window.Trustpilot) {
//     const iframe = ref?.valueOf as any
//     const iframedoc = iframe
//       ? iframe?.contentDocument || iframe?.contentWindow.document
//       : null
//     if (iframedoc) {
//       iframedoc.body.innerHtml = `<script src="//widget.trustpilot.com/product-questions/loader.js" data-business-unit-id="655517f1a5e07c2cc4bf7197" data-sku=""
//     data-style-width="100%" data-style-height="350px" data-locale="en-US" data-button-color="#1A66FF" data-button-text-color="#ffffff"></cript>`
//     }
//     window.Trustpilot.loadFromElement(ref?.valueOf, true)
//   }
// }, [])
// return (
//   <iframe ref={setContentRef as LegacyRef<HTMLIFrameElement>}>
//     {mountNode &&
//       createPortal(
//         <div>
//           <TrustpilotScriptLoader />
//           IFRAME
//           <script
//             src="//widget.trustpilot.com/product-questions/loader.js"
//             data-business-unit-id="655517f1a5e07c2cc4bf7197"
//             data-sku=""
//             data-style-width="100%"
//             data-style-height="350px"
//             data-locale="en-US"
//             data-button-color="#1A66FF"
//             data-button-text-color="#ffffff"
//           />
//         </div>,
//         mountNode
//       )}
//   </iframe>
// )
// }

export const TrustpilotMicroCombo = ({
  cssClass,
  dark,
  tall
}: {
  cssClass?: string;
  dark?: boolean;
  tall?: boolean;
}) => {
  const ref = useRef(null);
  const isMobile = useIsMobile();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, 1000);
    return () => {
      timeout;
    };
  }, []);
  return <HydrationContainer data-sentry-element="HydrationContainer" data-sentry-component="TrustpilotMicroCombo" data-sentry-source-file="index.tsx">
      <div ref={ref} className={classNames("mt-6 pointer-events-none mb-7 trustpilot-widget", cssClass ? cssClass : "")} data-locale="en-US" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id={buid} data-style-height={isMobile || tall ? "42px" : "24px"} data-style-width="100%" data-theme={dark ? "dark" : "light"} onClick={e => e.preventDefault()} data-availability={true}>
        {/* <center>
         <a
          href={
            IS_BAERSKIN
              ? "https://www.trustpilot.com/review/baerskinhoodie.com"
              : "https://www.trustpilot.com/review/baerskintactical.com"
          }
          target="_blank"
          rel="noreferrer"
          className="text-indigo-600 underline"
         >
          Click here to see reviews at Trustpilot &rarr;
         </a>
         </center> */}
      </div>
    </HydrationContainer>;
};
export const TrustpilotProductReviewsMultiSourceSEO = ({
  skuArray,
  price
}: {
  skuArray?: string[];
  price: number;
}) => {
  const ref = useRef(null);
  const skus = skuArray?.join(",");
  const {
    currencyCode
  } = useI10n();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, 1000);
    return () => {
      timeout;
    };
  }, []);
  return buid && skus && <HydrationContainer>
        <ContentBlockServer className="px-4 py-2 mb-4 md:px-12 lg:px-32">
          <div ref={ref} className="mt-8 mb-6 trustpilot-widget" data-locale="en-US" data-template-id="5763bccae0a06d08e809ecbb" data-businessunit-id={buid} data-sku={skus ?? ""} data-style-height="700px" data-style-width="100%" data-theme="light" data-name="BÆRSkin Tactical Hoodie" data-review-languages="en" data-no-reviews="hide" data-fullwidth="true" data-price={price} data-price-currency={currencyCode} data-availability={true}>
            <a href="https://www.trustpilot.com/review/baerskintactical.com" target="_blank" rel="noreferrer" className="text-indigo-600 underline" aria-label="Redirect to trustpilot in a new tab">
              Click here to see reviews at Trustpilot &rarr;
            </a>
          </div>
        </ContentBlockServer>
      </HydrationContainer>;
};
export const TrustpilotSlider = () => {
  const ref = useRef(null);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, 1000);
    return () => {
      timeout;
    };
  }, []);
  return <HydrationContainer data-sentry-element="HydrationContainer" data-sentry-component="TrustpilotSlider" data-sentry-source-file="index.tsx">
      <div ref={ref} className="trustpilot-widget" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id={buid} data-style-height="240px" data-style-width="100%" data-theme="light" data-tags="SelectedReview" data-review-languages="en" data-availability={true}>
        {/* <center className="my-10">
         <a
          href="https://www.trustpilot.com/review/baerskinhoodie.com"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-600 underline"
         >
          Click here to see reviews directly at Trustpilot &rarr;
         </a>
         </center> */}
      </div>
    </HydrationContainer>;
};
export const TrustpilotWrapper = ({
  block,
  product
}: {
  block: Payload.RemoteReviewsBlock;
  product?: Payload.Product;
}) => {
  const ref = useRef(null);
  const {
    currencyCode
  } = useI10n();
  const skus = useMemo(() => {
    const acc = [] as string[];
    if (product?.variants && product.variants?.length > 1) {
      product?.variants?.forEach(variant => {
        acc.push(variant.sku);
      });
    }
    return acc.length > 0 ? acc : product?.sku ? [product.sku] : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true);
      }
    }, 1000);
    return () => {
      timeout;
    };
  }, []);
  const price = (currencyCode && currencyCode !== "USD" ? product?.prices?.[0]?.[currencyCode] : product?.prices?.[0].price) as number;
  return <HydrationContainer data-sentry-element="HydrationContainer" data-sentry-component="TrustpilotWrapper" data-sentry-source-file="index.tsx">
      <section className="px-4 py-8">
        <header className="flex flex-col justify-center mb-2 space-y-3 md:flex-row md:space-y-0 md:space-x-3 ">
          <h3 className="text-3xl font-bold text-center sm:text-4xl">
            {block?.title && block?.title.length > 1 ? block.title : "Raving Fans From All Over The World"}
          </h3>
        </header>
        <Suspense fallback={<div>Loading...</div>} data-sentry-element="Suspense" data-sentry-source-file="index.tsx">
          {/* <TrustpilotProductQandA integrationKey={block.integrationKey} /> */}
          <TrustpilotProductReviewsMultiSourceSEO skuArray={skus} price={price} data-sentry-element="TrustpilotProductReviewsMultiSourceSEO" data-sentry-source-file="index.tsx" />
        </Suspense>
      </section>
    </HydrationContainer>;
};
export default TrustpilotWrapper;