import { IS_COZI, IS_HAMSLIDES } from "@local/anchor/src/const"
import type { StoreType } from "./types"

export const MINISTORE = process.env.NEXT_PUBLIC_CHANNEL_ID as StoreType
export const STORE_ID = process.env.NEXT_PUBLIC_LP_ID as string
export const STORE_NAME = process.env.NEXT_PUBLIC_CHANNEL_ID as StoreType

export const IS_BAERSKIN = MINISTORE === "baerskintactical"
export const IS_COZISTORE = MINISTORE === "cozistore"
export const IS_HAM = MINISTORE === "hamstore"

export const STORE_URL = process.env.NEXT_PUBLIC_APP_URL as string

export const STORE_EMAIL = IS_BAERSKIN
  ? "support@baerskintactical.com"
  : IS_HAM
    ? "support@hyperarchmotion.com"
    : IS_COZISTORE
      ? "support@cozislides.com"
      : "support@baerskintactical.com"

export const BAERSKIN_CWLP_ID = null //"6643ad80d4b5638d4caa6211"
export const BAERSKIN_CWLP_SLUG = null //"hoodie-cw"

export const ITEM_BRAND =
  IS_HAMSLIDES || IS_HAM
    ? "Hyper Arch Motion"
    : IS_COZI || IS_COZISTORE
      ? "Cozi"
      : IS_BAERSKIN
        ? "Baerskin Tactical"
        : null
