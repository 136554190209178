import { classNames } from "@local/utils/src/classNames";
import { cva } from "class-variance-authority";
import type { CSSProperties, ReactNode } from "react";
interface Props {
  children: ReactNode;
  className?: string;
  touchEdges?: boolean;
  fullWidthBgColor?: string;
  fullWidthBgImageUrl?: string;
  id?: string;
  style?: CSSProperties;
  role?: string;
}
export const ContentBlockServer = ({
  id,
  children,
  className,
  touchEdges,
  fullWidthBgColor,
  style,
  fullWidthBgImageUrl,
  role
}: Props) => {
  const styles = cva(className, {
    variants: {
      touchEdges: {
        true: "w-full",
        false: "mx-auto max-w-7xl"
      }
    },
    defaultVariants: {
      touchEdges: false
    }
  });
  return <div className={classNames("w-full content-block", fullWidthBgColor ? fullWidthBgColor : "")} id={id ?? ""} style={fullWidthBgImageUrl ? {
    ...style,
    backgroundImage: fullWidthBgImageUrl
  } : style} role={role} data-sentry-component="ContentBlockServer" data-sentry-source-file="ContentBlock.tsx">
      <div className={styles({
      touchEdges
    })}>{children}</div>
    </div>;
};