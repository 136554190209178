"use client";

import { ErrorBoundary } from "./ErrorBoundary";
import { type ReactNode, Suspense, useEffect, useState } from "react";
export const HydrationContainer = ({
  children,
  fallback,
  loadingSkeleton
}: {
  children: ReactNode;
  fallback?: ReactNode;
  loadingSkeleton?: ReactNode;
}) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
    return () => {
      setIsClient(false);
    };
  }, []);
  return isClient ? <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="HydrationContainer" data-sentry-source-file="HydrationContainer.tsx">
      <Suspense fallback={<>{loadingSkeleton}</>} data-sentry-element="Suspense" data-sentry-source-file="HydrationContainer.tsx">{children}</Suspense>
    </ErrorBoundary> : fallback ? <>{fallback}</> : null;
};
export default HydrationContainer;