import { MINISTORE } from "@/lib/const";
import { classNames } from "@local/utils/src/classNames";
import { cva } from "class-variance-authority";
export enum ButtonIntent {
  CTA = "cta",
  DarkCTA = "darkcta",
  OutlinedCTA = "outlinedcta",
  Success = "success",
}
export enum ButtonStretch {
  full = "full",
  fixed = "fixed",
  flexible = "flexible",
  smaller = "smaller",
  huge = "huge",
}
interface ButtonProps {
  content?: string;
  intent?: ButtonIntent;
  stretch?: ButtonStretch;
  disabled?: boolean;
  className?: string;
  url?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>, express?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | {} | Promise<void>;
  submit?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  id?: string;
}
const buttonStyles = cva("flex flex-row text-center justify-center py-[18px] align-middle text-lg font-medium transition-all leading-[0.6em] box-border whitespace-nowrap border-Width__Border__Button_outlined hover:no-underline", {
  variants: {
    intent: {
      cta: ["border-transparent", MINISTORE === "baerskintactical" ? "bg-Color__Bg__Button_baerskin hover:bg-Color__Bg__Button__Hover_baerskin rounded-md" : "bg-Color__Bg__Button hover:bg-Color__Bg__Button__Hover rounded-full", "text-Color__Text__Button", "hover:text-Color__Text__Button__Hover"],
      darkcta: ["border-transparent", "bg-Color__Bg__Button_dark", "hover:bg-Color__Bg__Button_dark__Hover", "text-Color__Text__Button", "hover:text-Color__Text__Button_dark__Hover", MINISTORE === "baerskintactical" ? "rounded-md" : "rounded-full"],
      outlinedcta: ["bg-Color__Bg__Button_outlined", "border-Color__Border__Button_outlined", "hover:border-Color__Border__Button_outlined__Hover", "text-Color__Text__Button_outlined", "hover:bg-Color__Bg__Button__Hover", "hover:text-Color__Text__Button__Hover", "hover:text-Color__Text__Button_outlined__Hover", MINISTORE === "baerskintactical" ? "rounded-md" : "rounded-full"],
      success: ["bg-green-500", MINISTORE === "baerskintactical" ? "rounded-md" : "rounded-full", "text-Color__Text__Button", "hover:bg-black"]
    },
    stretch: {
      full: "w-full",
      fixed: "w-full sm:w-[340px] md:w-[360px] px-16 lg:px-20",
      flexible: "w-auto px-16",
      smaller: "w-full sm:w-fit py-[12px] px-4 text-sm",
      huge: "w-full md:w-fit mx-auto !py-4 !md:py-6 mb-2 md:mb-0 md:px-16 font-bold md:text-xl tracking-wider !text-base overflow-hidden"
    },
    disabled: {
      true: "cursor-not-allowed opacity-50"
    }
  },
  defaultVariants: {
    intent: "cta",
    stretch: "fixed"
  }
});
const ButtonServer = ({
  submit,
  content,
  intent,
  stretch,
  disabled,
  className,
  url,
  onClick,
  ref,
  id
}: ButtonProps) => {
  if (url) {
    return <a className={classNames(buttonStyles({
      intent,
      stretch,
      disabled
    }), className ?? "")} href={url} id={id}>
        {content ?? "Click Here"}
      </a>;
  } else {
    return <button className={classNames(buttonStyles({
      intent,
      stretch,
      disabled
    }), className ?? "")} disabled={disabled ? true : false} onClick={onClick} type={submit ? "submit" : "button"} ref={ref} id={id}>
        {content ?? "Click Here"}
      </button>;
  }
};
export default ButtonServer;