"use client";

// TODO: Make this server
import Image from "next/image";
import { ImageAmex, ImageApple, ImageDiscover, ImageDiners, ImageGoogle, ImageMastercard, ImagePaypal, ImageVisa, ImageKlarna } from "./images";
import { useI10n } from "@local/i10n/src/useI10n";
interface Props {
  limit?: number;
  fillHeight?: boolean;
  noPaypal?: boolean;
}
type CardItem = {
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  src: typeof import("*.svg");
  alt: string;
};
type CardProps = CardItem & Props;
const Card = ({
  src,
  alt,
  fillHeight
}: CardProps) => <div className="relative h-12 w-[46px] rounded border-4 border-white bg-white" style={fillHeight ? {
  height: "100%",
  width: "auto"
} : {}} data-sentry-component="Card" data-sentry-source-file="index.tsx">
    <Image src={src} alt={alt} style={fillHeight ? {
    height: "100%",
    width: "auto"
  } : {}} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
  </div>;
const CreditCards = ({
  limit,
  fillHeight,
  noPaypal
}: Props) => {
  const {
    country
  } = useI10n();
  const cards: CardItem[] = [{
    src: ImageVisa,
    alt: "Visa"
  }, {
    src: ImageMastercard,
    alt: "Mastercard"
  }, {
    src: ImageAmex,
    alt: "American Express"
  }, {
    src: ImageDiscover,
    alt: "Discover"
  }, {
    src: ImageApple,
    alt: "Apple Pay"
  }, {
    src: ImageDiners,
    alt: "Diners Club"
  }, {
    src: ImageGoogle,
    alt: "Google Pay"
  }];
  if (!noPaypal) {
    cards.push({
      src: ImagePaypal,
      alt: "Paypal"
    });
  }
  if (process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID && country === "us") {
    cards.push({
      src: ImageKlarna,
      alt: "Klarna"
    });
  }
  return <div className="flex w-full flex-row items-center justify-between space-x-2 align-middle overflow-hidden" style={fillHeight ? {
    height: "100%"
  } : {}} data-sentry-component="CreditCards" data-sentry-source-file="index.tsx">
      {cards.slice(0, limit ?? cards.length).map(card => <Card key={card.alt} src={card.src} alt={card.alt} fillHeight={fillHeight} />)}
    </div>;
};
export { Card, CreditCards };