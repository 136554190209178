import type { Payload } from "@local/payload-client/src/types"

export const STORE_NAME = process.env.NEXT_PUBLIC_CHANNEL_ID
export const STORE_ID = process.env.NEXT_PUBLIC_LP_ID as string

export const GET_SINGLE_CHANNEL_ID = () => {
  const STORE_NAME = process.env.NEXT_PUBLIC_CHANNEL_ID

  const IS_BAERSKIN_ONLY = STORE_NAME === "baerskinhoodie"
  const IS_BOMBERJACKET = STORE_NAME === "bomberjacket"
  const IS_BOXERS = STORE_NAME === "boxers"
  const IS_COZI = STORE_NAME === "cozislides"
  const IS_FREEDOM = STORE_NAME === "freedomslides"
  const IS_HAM_ONLY = STORE_NAME === "ham"
  const IS_HAMSLIDES = STORE_NAME === "hamslides"
  const IS_HAM = IS_HAM_ONLY || IS_HAMSLIDES
  const IS_LUMBERJACKET = STORE_NAME === "lumberjacket"
  const IS_NOBSTEES = STORE_NAME === "nobstees"
  const IS_RAINJACKET = STORE_NAME === "rainjacket"
  const IS_SOFTSHELL = STORE_NAME === "softshell"
  const IS_VEST = STORE_NAME === "vest"

  const IS_BAERSKIN = !IS_COZI && !IS_HAM

  return {
    IS_BAERSKIN_ONLY,
    IS_BAERSKIN,
    IS_BOMBERJACKET,
    IS_BOXERS,
    IS_COZI,
    IS_FREEDOM,
    IS_HAM,
    IS_HAM_ONLY,
    IS_HAMSLIDES,
    IS_LUMBERJACKET,
    IS_NOBSTEES,
    IS_RAINJACKET,
    IS_SOFTSHELL,
    IS_VEST,
    STORE_NAME,
  }
}

export const GET_ANY_CHANNEL_ID = (
  storeId?: Payload.AnchorChannelId | null
) => {
  return storeId ? GET_MULTI_CHANNEL_ID(storeId) : GET_SINGLE_CHANNEL_ID()
}

export const GET_MULTI_CHANNEL_ID = (storeId: Payload.AnchorChannelId) => {
  const IS_BAERSKIN_ONLY = storeId === "baerskinhoodie"
  const IS_BOMBERJACKET = storeId === "bomberjacket"
  const IS_BOXERS = storeId === "boxers"
  const IS_COZI = storeId === "cozislides"
  const IS_FREEDOM = storeId === "freedomslides"
  const IS_HAM_ONLY = STORE_NAME === "ham" || storeId === "hamstore"
  const IS_HAMSLIDES = STORE_NAME === "slides"
  const IS_HAM = IS_HAM_ONLY || IS_HAMSLIDES
  const IS_LUMBERJACKET = storeId === "lumberjacket"
  const IS_NOBSTEES = storeId === "nobstees"
  const IS_RAINJACKET = storeId === "rainjacket"
  const IS_SOFTSHELL = storeId === "softshell"
  const IS_VEST = storeId === "vest"

  const IS_BAERSKIN = !IS_COZI && !IS_HAM

  return {
    IS_BAERSKIN_ONLY,
    IS_BAERSKIN,
    IS_BOMBERJACKET,
    IS_BOXERS,
    IS_COZI,
    IS_FREEDOM,
    IS_HAM_ONLY,
    IS_HAM,
    IS_HAMSLIDES,
    IS_LUMBERJACKET,
    IS_NOBSTEES,
    IS_RAINJACKET,
    IS_SOFTSHELL,
    IS_VEST,
    STORE_NAME,
  }
}

export const SITE_URL = process.env.NEXT_PUBLIC_APP_URL as string
export const SITE_HOSTNAME = new URL(SITE_URL).hostname
export const SITE_TLD =
  SITE_HOSTNAME.split(".").length > 2
    ? SITE_HOSTNAME.split(".").slice(1).join(".")
    : SITE_HOSTNAME

// DEPRECATE THIS STUFF LATER
export const IS_COZI = STORE_NAME === "cozislides"
export const IS_HAM = STORE_NAME === "ham"
export const IS_HAMSLIDES = STORE_NAME === "hamslides"

//baerskin
export const IS_BAERSKIN_ONLY = STORE_NAME === "baerskinhoodie"
export const IS_FREEDOM = STORE_NAME === "freedomslides"
export const IS_NOBSTEES = STORE_NAME === "nobstees"
export const IS_RAINJACKET = STORE_NAME === "rainjacket"
export const IS_LUMBERJACKET = STORE_NAME === "lumberjacket"
export const IS_BOMBERJACKET = STORE_NAME === "bomberjacket"
export const IS_SOFTSHELL = STORE_NAME === "softshell"
export const IS_BOXERS = STORE_NAME === "boxers"

export const IS_BAERSKIN =
  IS_BAERSKIN_ONLY ||
  IS_FREEDOM ||
  IS_NOBSTEES ||
  IS_RAINJACKET ||
  IS_LUMBERJACKET ||
  IS_BOMBERJACKET ||
  IS_SOFTSHELL ||
  IS_BOXERS

export const ALLOWED_COUNTRIES = [
  "au",
  "ca",
  "ch",
  "de",
  "eu",
  "gb",
  "nz",
  "us",
]

export const HAM_AFFILIATE_PAGE_ID = "148692149"

export const EMAIL_URL = IS_BAERSKIN
  ? "baerskintactical.com"
  : new URL(SITE_URL as string).hostname

export const ITEM_BRAND =
  IS_HAMSLIDES || IS_HAM
    ? "Hyper Arch Motion"
    : IS_COZI
      ? "Cozi"
      : IS_BAERSKIN
        ? "Baerskin Tactical"
        : null
