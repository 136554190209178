"use client";

import { Component, type ErrorInfo, type ReactNode } from "react";
interface Props {
  children?: ReactNode;
}
interface State {
  error?: Error | null;
  hasError: boolean;
}
export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
    hasError: false
  };
  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      error,
      hasError: true
    };
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }
  public render() {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === "production") {
        console.error(this.state.error);
        return <span className="display: none" dangerouslySetInnerHTML={{
          __html: `<!-- COMPONENT ERROR ${this.state.error ? `: ${this.state.error}` : ""}-->`
        }} data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx" />;
      }
      if (process.env.NODE_ENV === "development") {
        return <pre data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">COMPONENT ERROR</pre>;
      }
      return null;
    }
    return <>{this.props.children}</>;
  }
}
export default ErrorBoundary;