/* eslint-disable @next/next/no-img-element */
import type { CSSProperties } from "react";
import { classNames } from "@local/utils/src/classNames";
import type { ImageLoader } from "next/image";
interface Props {
  alt?: string;
  className?: string;
  fill?: boolean;
  height?: number;
  loader?: ImageLoader;
  loading?: "lazy" | "eager";
  priority?: boolean;
  quality?: number;
  sizes?: string;
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  src: any;
  style?: CSSProperties;
  width?: number;
}
export const Image = ({
  alt,
  className,
  loading,
  quality,
  src,
  height,
  style,
  width
}: Props) => {
  const bunnyUrl = process.env.NEXT_PUBLIC_BUNNY_URL ? process.env.NEXT_PUBLIC_BUNNY_URL : null;
  let url = src;
  if (bunnyUrl && typeof src === "string" && width) {
    const MAX_WIDTH = 1280;
    const widthToPull = width > MAX_WIDTH ? MAX_WIDTH : width;

    // Clear up the urls from PayloadCMS
    const file = src.replace("https://assets.stg.div.haus/", "").replace("https://assets.div.haus/", "").replace("/", "");
    const iurl = new URL(`https://${bunnyUrl}/${file}`);
    const params = iurl.searchParams;
    params.set("quality", (quality || 50).toString());
    params.set("width", params.get("width") || widthToPull.toString());
    url = iurl.href;
  }
  return <img alt={alt} className={classNames(className ?? "", "max-w-full")} height={height} loading={loading} src={url} style={style} width={width} data-sentry-component="Image" data-sentry-source-file="Image.tsx" />;
};
export default Image;