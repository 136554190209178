export * as ImageAmex from "./amex.svg"
export * as ImageApple from "./apple.svg"
export * as ImageDiners from "./diners.svg"
export * as ImageDiscover from "./discover.svg"
export * as ImageFlex from "./flex.svg"
export * as ImageGoogle from "./google.svg"
export * as ImageKlarna from "./klarna.svg"
export * as ImageMastercard from "./mastercard.svg"
export * as ImagePaypal from "./paypal.svg"
export * as ImageVisa from "./visa.svg"
